<template>
  <div>
    <div class="health-bg linear-blue"></div>

    <van-pull-refresh v-model="reFreshLoading" @refresh="onRefresh">
      <div>
        <div class="myinfo-box padding15">
          <base-avatar :src="userInfo.header" height="60"></base-avatar>
          <div class="info">
            <div class="name">
              {{ userInfo.memberName }}
              <span>{{ userInfo.doctorTitle }}</span>
            </div>
            <div class="tip">{{ userInfo.hospitalName }} {{ userInfo.department }}</div>
          </div>
          <div class="flex">
            <div class="right-ico">
              <van-image
                fit="fill"
                style="width: 100%; height: 100%"
                @click.stop="handleNavigation('qrCode')"
                :src="require('@/assets/health-qrcode.png')"
              />
            </div>
            <van-badge :dot="myData.noReadMessage > 0">
              <div class="right-ico">
                <van-image
                  fit="fill"
                  style="width: 100%; height: 100%"
                  @click.stop="handleNavigation('messageList')"
                  :src="require('@/assets/health-message.png')"
                />
              </div>
            </van-badge>
          </div>
        </div>

        <div class="health-depart padding15">
          <div class="title">
            <van-image
              width="25px"
              height="25px"
              fit="fill"
              position="left"
              :src="require('@/assets/health-depart.png')"
            />
            我的诊室
          </div>
          <van-button class="setting" size="mini" type="primary" round to="/setting">
            <template #icon>
              <van-icon name="setting-o" color="white" />
              问诊设置
            </template>
          </van-button>
        </div>
        <div class="health-fast padding15">
          <router-link to="/doctorFastOrder">
            <div class="tofast">
              <div class="top">
                抢单区
                <van-image fit="fill" position="left" :src="require('@/assets/health-arrow.png')" />
              </div>
              <div class="bottom">
                <span>{{ myData.fastOrderCount }}</span
                >单待抢
              </div>
            </div>
          </router-link>
          <router-link to="/doctorOrder/1">
            <div class="tofast">
              <div class="top">
                待接诊
                <van-image fit="fill" position="left" :src="require('@/assets/health-arrow.png')" />
              </div>
              <div class="bottom">
                <div>
                  <span style="color: #5e7dff">{{ myData.daiJieZhenCount }}</span>
                </div>
              </div>
            </div>
          </router-link>
          <router-link to="/doctorOrder/2">
            <div class="tofast">
              <div class="top">
                待回复
                <van-image fit="fill" position="left" :src="require('@/assets/health-arrow.png')" />
              </div>
              <div class="bottom">
                <div>
                  <span style="color: #5e7dff">{{ myData.daiHuiFuCount }}</span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="padding15">
          <base-box padding="15px 4px 30px">
            <van-grid :border="false" :gutter="5">
              <van-grid-item v-for="(item, index) in gridList" :key="index" :to="item.to">
                <van-image :src="item.img" width="30" height="30" />
                <div class="grid-title">
                  {{ item.title }}
                </div>
                <div v-if="myData[item.round]" class="round"></div>
              </van-grid-item>
              <van-grid-item>
                <template>
                  <wx-open-launch-weapp username="gh_ee1d7bf44505" path="pages/index/index" class="launch-weapp">
                    <div v-is="'script'" type="text/wxtag-template">
                      <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
                        <img
                          src="http://file.bzjkpt.com/95f258de-6d2e-44de-951e-e28547b6a62b.png"
                          style="width: 30px; height: 30px"
                        />
                        <div style="font-size: 13px; margin-top: 10px; color: #2c3e50">肌肤商城</div>
                      </div>
                    </div>
                  </wx-open-launch-weapp>
                </template>
              </van-grid-item>
            </van-grid>
          </base-box>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import BaseBox from '@/components/BaseBox.vue';
import { Badge, Button, Grid, GridItem, Icon, Image, PullRefresh } from 'vant';
import { myGridList } from '@/utils/data.js';
import store from '@/store';
import BaseAvatar from '@/components/BaseAvatar.vue';
import { useRouter } from 'vue-router';
import { getPersonalCenter } from '@/api/home';
import { ref } from '@vue/reactivity';
import { onBeforeUnmount, onMounted } from 'vue-demi';
import { useIntervalFn } from '@vueuse/core';

export default {
  name: 'health',
  components: {
    BaseBox,
    BaseAvatar,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [PullRefresh.name]: PullRefresh,
    [Badge.name]: Badge,
  },
  setup() {
    const userInfo = ref(store.getters['user/userInfo']);
    const gridList = myGridList.filter((v) => v.isDoctor == userInfo.value.isDoctor || v.isDoctor == undefined);

    const router = useRouter();
    const handleNavigation = function (name) {
      router.push({ name });
    };

    const { pause, resume, isActive } = useIntervalFn(() => {
      getMypageInfo();
    }, 10000);

    onMounted(() => {
      resume();
    });
    onBeforeUnmount(() => {
      if (isActive.value) {
        pause();
      }
    });

    const myData = ref({});
    const getMypageInfo = function () {
      getPersonalCenter().then((res) => {
        myData.value = res.data;
        userInfo.value = res.data.userInfo;
        store.commit('user/setUserInfo', res.data.userInfo);
        reFreshLoading.value = false;
      });
    };
    getMypageInfo();

    const reFreshLoading = ref(false);
    const onRefresh = function () {
      reFreshLoading.value = true;
      getMypageInfo();
    };

    return {
      gridList,
      userInfo,
      myData,
      handleNavigation,
      onRefresh,
      reFreshLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.round {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #ff5e5e;
  position: absolute;
  top: 15px;
  right: 18px;
}
.health-bg {
  height: 375px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.myinfo-box {
  background: linear-gradient(135deg, #90aafe, #5e7dff);
  box-shadow: 0px 5px 20px 0px rgba(95, 126, 255, 0.5);
  .info {
    color: white;
  }

  .flex {
    display: flex;
    .right-ico {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      display: flex;
    }
  }
}

.health-fast {
  display: flex;
  justify-content: space-between;
  .tofast {
    width: 80px;
    height: 60px;
    background: linear-gradient(135deg, #dae1ff, #ffffff);
    box-shadow: 0px 5px 20px 0px rgba(95, 126, 255, 0.8);
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0px;
    .van-image {
      width: 15px;
      height: 15px;
    }
    .top {
      font-size: $font14;
      color: #5e7dff;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .bottom {
      display: block;
      span {
        line-height: 23px;
        font-size: $font30;
        color: #ff5e5e;
      }
      font-size: $font12;
      color: $black;
    }
  }
}

.health-depart {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
    font-size: $font15;
    .van-image {
      margin-right: 5px;
    }
  }
  i {
    font-size: $font15;
  }
  .setting {
    font-size: $font12;
    width: 100px;
    height: 25px;
    background-color: rgba($color: white, $alpha: 0);
    border: 1px solid #bfcbff;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
</style>
